import React, { useEffect,useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { SUMMER } from '../../utils/moodTypes';
import { useForm } from "react-hook-form";

import { subscribeEmailHttp } from "../../http/http-requests";
import { FOOTERHttp } from "../../http/wordpress/http-requests-wordpress";

import useMoodHook from "../../shared/hooks/moodHook";

import MenuIcon from "../Menu/MenuIcon";
import NotFoundSection from "../NotFoundSection/NotFoundSection";

// Images
import newsletterLogo from "../../assets/images/ARION LOGO.svg";
import FacebookLogo from '../SocialIcons/FacebookLogo';
import InstagramLogo from '../SocialIcons/InstagramLogo';
import Tripadvisor from "../SocialIcons/Tripadvisor";
import GoogleMaps from "../SocialIcons/GoogleMaps";
// Css
import "./Footer.scss";

const Footer = () => {
  const location = useLocation();
  const path = location.pathname.split("/")[1] === "" ? "home" : location.pathname.split("/")[1];

  const { register, formState: { errors }, reset, handleSubmit } = useForm();
  const { mood } = useMoodHook();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [footerData, setFooterData] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await FOOTERHttp();
        if (response.status === 200) {
          setFooterData(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [mood]);

  const scrollTo = () => {
    window.scrollTo({ top: 200, behavior: "smooth" });
  };

  const onSubmitHandler = async (data) => {
    // e.preventDefault()
    setLoading(true);
    setError(false);
    setSuccess(false);
    try {
      await subscribeEmailHttp(data);
      setSuccess(true);
      reset();

      document.getElementById("subscriptionForm").reset();
      setTimeout(() => {
        document.querySelector(".email-success").style.display = "none";
      }, 4000);

      setTimeout(() => {
        if (window.innerWidth < 768) {
          scrollTo();
        }
      }, 500);
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  let errorEmailRequired;
  let errorEmailInvalid;
  if (errors.email?.type === "required") {
    errorEmailRequired = <span className="error-msg">Bitte E-Mail Adresse eingeben</span>;
  }
  if (errors.email?.type === "pattern") {
    errorEmailInvalid = <span className="error-msg">E-Mail Format ist ungültig</span>;
  }
  const trapSpacesForRequiredFields = (value) => !!value.trim();

  return (
    <footer className={path ? path : ""}>
      {
        footerData !== null ?
          <>
            <div className="container">
              <div className="row">
                <div className="footerInner">
                  <div className="contact">
                    <h2 dangerouslySetInnerHTML={{
                      __html: footerData[0].contactTitle,
                    }}></h2>
                    <div dangerouslySetInnerHTML={{
                      __html: footerData[0].contactContent,
                    }}></div>
                  </div>

                  <div className="newsletter">
                    <Link to="/">
                      <img src={footerData[0].logoUrl ? footerData[0].logoUrl : newsletterLogo} alt="Arion Logo" />
                    </Link>
                    <div className="socialIcons">
                      <a href={footerData[0].socials[0].facebook} target="_blank" rel="noreferrer">
                        <FacebookLogo />
                      </a>
                      <a href={footerData[0].socials[0].instagram} target="_blank" rel="noreferrer">
                        <InstagramLogo />
                      </a>

                      <a href={footerData[0].socials[0].tripadvisor} target="_blank" rel="noreferrer">
                        <Tripadvisor />
                      </a>

                      <a href={footerData[0].socials[0].googleMaps} target="_blank" rel="noreferrer">
                        <GoogleMaps />
                      </a>
                    </div>
                    <form id="subscriptionForm" autoComplete="off" onSubmit={handleSubmit(onSubmitHandler)}>
                      <label>Newsletter-Anmeldung</label>
                      <div className="emailSubmit">
                        <div className="inputHolder">
                          <input
                            name="email"
                            type="text"
                            placeholder="Deine E-Mail"
                            {...register('email', {
                              required: true,
                              pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              validate: trapSpacesForRequiredFields,
                            })} 
                            onKeyUp={()=>{setSuccess(false)}}
                            />
                          {errorEmailRequired}
                          {errorEmailInvalid}
                          {success && (
                            <span className="email-success">
                              Du hast dich erfolgreich angemeldet!
                            </span>
                          )}
                          {error && (
                            <span className="email-fail">Etwas ist schief gelaufen !</span>
                          )}
                        </div>
                        <button type="submit" disabled={loading ? true : false} className={mood ? "winter" : ""}>Go!</button>

                      </div>
                    </form>
                    <div className="footerLinks">
                      <a href={mood === SUMMER ? footerData[0].pdfButtonSommer ? footerData[0].pdfButtonSommer.url : '' : footerData[0].pdfButtonWinter ? footerData[0].pdfButtonWinter.url : ''} target={mood === SUMMER ? footerData[0].pdfButtonSommer ? footerData[0].pdfButtonSommer.target : '' : footerData[0].pdfButtonWinter ? footerData[0].pdfButtonWinter.target : ''} rel="noreferrer" className="button" >
                        {mood === SUMMER ? footerData[0].pdfButtonSommer ? footerData[0].pdfButtonSommer.title : '' : footerData[0].pdfButtonWinter ? footerData[0].pdfButtonWinter.title : ""}
                      </a>
                      <ul>
                        <li>
                          <Link to="/impressum">{footerData[0].pageLinks[0].title}</Link>
                        </li>
                        <li>
                          <Link to="/privacy">{footerData[0].pageLinks[1].title}</Link>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="openHours" dangerouslySetInnerHTML={{
                    __html: footerData[0].openHours,
                  }}>
                  </div>
                </div>
              </div>
            </div>
            <MenuIcon menuLink={mood === SUMMER ? footerData[0].pdfButtonSommer ? footerData[0].pdfButtonSommer.url : '' : footerData[0].pdfButtonWinter ? footerData[0].pdfButtonWinter.url : ''} />
          </>
          :
          <NotFoundSection />
      }
    </footer>

  );
}

export default Footer;
