
import './NotFoundSection.scss';

const NotFoundSection = ({sectionType}) => {
    return (
        <div className="containerFull">
            <div className="row">
                <div className="sectionNotFoundHolder">

                    <div className="sectionNotFoundHolderImg">

                        <svg id="Group_216" data-name="Group 216" xmlns="http://www.w3.org/2000/svg" width="103.458" height="103.458" viewBox="0 0 103.458 103.458">
                            <g id="Group_223" data-name="Group 223">
                                <path id="Path_182" data-name="Path 182" d="M51.729,0a51.729,51.729,0,1,0,51.729,51.729A51.788,51.788,0,0,0,51.729,0Zm0,97.4A45.667,45.667,0,1,1,97.4,51.729,45.719,45.719,0,0,1,51.729,97.4Z" fill="#78c9d8" />
                            </g>
                            <path id="Path_183" data-name="Path 183" d="M117.986,164.912a3.031,3.031,0,1,0,5.735-1.963,13.235,13.235,0,0,0-25.047.009,3.031,3.031,0,1,0,5.737,1.96,7.173,7.173,0,0,1,13.575,0Z" transform="translate(-78.606 -122.882)" fill="#78c9d8" />
                            <path id="Path_184" data-name="Path 184" d="M300.684,154a13.234,13.234,0,0,0-12.525,8.957,3.031,3.031,0,1,0,5.737,1.96,7.173,7.173,0,0,1,13.575,0,3.031,3.031,0,0,0,5.735-1.963A13.236,13.236,0,0,0,300.684,154Z" transform="translate(-229.801 -122.882)" fill="#78c9d8" />
                            <path id="Path_185" data-name="Path 185" d="M191.936,316.924a3.03,3.03,0,0,0-4.155,1.052,18.186,18.186,0,0,1-31.223.038,3.031,3.031,0,0,0-5.2,3.115,24.248,24.248,0,0,0,41.631-.051,3.031,3.031,0,0,0-1.052-4.155Z" transform="translate(223.913 396.869) rotate(180)" fill="#78c9d8" />
                        </svg>

                    </div>
                    <div className="sectionNotFoundHolderContent">
                        <h1>No Section</h1>
                        <p>
                            (({sectionType}))
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotFoundSection; 