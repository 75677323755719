import React, { lazy, Suspense } from 'react'
import { Routes, Route } from 'react-router-dom';
import Spinner from "../components/Spinner/Spinner";

const Home = lazy(() => import('../pages/Home/Home'))
const Kontakt = lazy(() => import("../pages/Kontakt/Kontakt"));
const UberUns = lazy(() => import("../pages/UberUns/UberUns"));
const Freizeit = lazy(() => import("../pages/Freizeit/Freizeit"));
const Gastronomie = lazy(() => import("../pages/Gastronomie/Gastronomie"));
const News = lazy(() => import("../pages/News/News"));
const Impressum = lazy(() => import("../pages/Impressum/Impressum"));
const Privacy = lazy(() => import("../pages/Privacy/Privacy"));
const NotFound = lazy(() => import("../pages/NotFound/NotFound"));

const routes = [
  {
    path: "/",
    component: <Home />,
  },
  {
    path: "/news",
    component: <News />,
  },
  {
    path: "/freizeit",
    component: <Freizeit />,
  },
  {
    path: "/gastronomie",
    component: <Gastronomie />,
  },
  {
    path: "/uber-uns",
    component: <UberUns />,
  },
  {
    path: "/kontakt",
    component: <Kontakt tab={1} />,
  },
  {
    path: "/tischreservation",
    component: <Kontakt tab={0} />,
  },
  {
    path: "/impressum",
    component: <Impressum />,
  },
  {
    path: "/privacy",
    component: <Privacy />,
  },
  {
    path: "*",
    component: <NotFound />,
  },
];

const Router = () => {
  return (
    <div className="route-wrapper">
      <Suspense fallback={<Spinner />}>
        <Routes>
          {
            routes.map(({ path, component }, index) => {
              return <Route key={index} path={path} element={component} exact />
            })
          }
        </Routes>
      </Suspense>
    </div>
  );
}

export default Router;
