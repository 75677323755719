import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { HEADERHttp } from "../../http/wordpress/http-requests-wordpress";

import useMoodHook from "../../shared/hooks/moodHook";
import { WINTER, SUMMER } from "../../utils/moodTypes";

import Logo from "../../assets/images/logo.png";

import HamburgerIcon from "./HamburgerIcon";

import "./Header.scss";

const Header = () => {
  const [open, setOpen] = useState(false);
  const [headerData, setHeaderData] = useState(null);
  const { mood, setSummerHandler, setWinterHandler } = useMoodHook();
  const [navbarFixed, setNavbarFixed] = useState(false);

  const toggleNavbarHandler = () => {
    open === false ? setOpen(true) : setOpen(false);
  };

  const closeNavbarHandlder = (e) => {
    setOpen(false);
  };

  const activeNavbarClasses = open ? "active" : "";

  const handleScroll = () => {
    const scrollTop = window.pageYOffset;
    if (scrollTop >= 110) {
      setNavbarFixed(true);
    } else {
      setNavbarFixed(false);
    }
  };

  const escapeClick = (e) => {
    if (e.keyCode === 27) {
      setOpen(false);
    }
  }
  const navbarClasses = navbarFixed ? "hasScrolled" : "";

  useEffect(() => {

    if (open) {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    } else {
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    }

  }, [open]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("keyup", escapeClick);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("keyup", escapeClick);
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await HEADERHttp();
        if (response.status === 200) {
          setHeaderData(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  return (
    <header className={`header ${navbarClasses}`}>
      <div className="header-container">
        <NavLink to="/" className="logo-wrapper" >
          <img src={headerData !== null ? headerData.logoUrl : Logo} alt="logo" className="logo" />
        </NavLink>

        <ul className="mood-nav">
          <li>
            <button
              className={`mood-btn smr ${mood === SUMMER ? "active" : ""
                }`}
              onClick={setSummerHandler}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="81"
                height="81"
                viewBox="0 0 81 81"
              >
                <defs>
                  <filter
                    id="Path_165"
                    x="10.639"
                    y="10.639"
                    width="59.722"
                    height="59.722"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="5" result="blur" />
                    <feFlood floodOpacity="0.169" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Path_166"
                    x="23.406"
                    y="0"
                    width="34.189"
                    height="37.381"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="5" result="blur-2" />
                    <feFlood floodOpacity="0.169" />
                    <feComposite operator="in" in2="blur-2" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Path_167"
                    x="0"
                    y="23.406"
                    width="37.381"
                    height="34.189"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="5" result="blur-3" />
                    <feFlood floodOpacity="0.169" />
                    <feComposite operator="in" in2="blur-3" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Path_168"
                    x="23.406"
                    y="43.619"
                    width="34.189"
                    height="37.381"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="5" result="blur-4" />
                    <feFlood floodOpacity="0.169" />
                    <feComposite operator="in" in2="blur-4" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Path_169"
                    x="43.619"
                    y="23.406"
                    width="37.381"
                    height="34.189"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="5" result="blur-5" />
                    <feFlood floodOpacity="0.169" />
                    <feComposite operator="in" in2="blur-5" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Path_170"
                    x="38.3"
                    y="7.447"
                    width="35.253"
                    height="35.253"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="5" result="blur-6" />
                    <feFlood floodOpacity="0.169" />
                    <feComposite operator="in" in2="blur-6" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Path_171"
                    x="7.447"
                    y="7.447"
                    width="35.253"
                    height="35.253"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="5" result="blur-7" />
                    <feFlood floodOpacity="0.169" />
                    <feComposite operator="in" in2="blur-7" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Path_172"
                    x="7.447"
                    y="38.3"
                    width="35.253"
                    height="35.253"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="5" result="blur-8" />
                    <feFlood floodOpacity="0.169" />
                    <feComposite operator="in" in2="blur-8" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Path_173"
                    x="38.3"
                    y="38.3"
                    width="35.255"
                    height="35.253"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="5" result="blur-9" />
                    <feFlood floodOpacity="0.169" />
                    <feComposite operator="in" in2="blur-9" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                </defs>
                <g
                  id="Group_210"
                  data-name="Group 210"
                  transform="translate(-556 -3.625)"
                >
                  <g id="sol" transform="translate(571 18.625)">
                    <g
                      transform="matrix(1, 0, 0, 1, -15, -15)"
                      filter="url(#Path_165)"
                    >
                      <path
                        id="Path_165-2"
                        data-name="Path 165"
                        d="M121.528,106.667a14.861,14.861,0,1,0,14.861,14.861A14.878,14.878,0,0,0,121.528,106.667Zm0,25.533A10.672,10.672,0,1,1,132.2,121.528,10.684,10.684,0,0,1,121.528,132.2Z"
                        transform="translate(-81.03 -81.03)"
                      />
                    </g>
                    <g
                      transform="matrix(1, 0, 0, 1, -15, -15)"
                      filter="url(#Path_166)"
                    >
                      <path
                        id="Path_166-2"
                        data-name="Path 166"
                        d="M236.762,7.381a2.1,2.1,0,0,0,2.095-2.095V2.095a2.095,2.095,0,1,0-4.189,0V5.286A2.094,2.094,0,0,0,236.762,7.381Z"
                        transform="translate(-196.26 15)"
                      />
                    </g>
                    <g
                      transform="matrix(1, 0, 0, 1, -15, -15)"
                      filter="url(#Path_167)"
                    >
                      <path
                        id="Path_167-2"
                        data-name="Path 167"
                        d="M5.286,234.667H2.095a2.095,2.095,0,1,0,0,4.189H5.286a2.095,2.095,0,1,0,0-4.189Z"
                        transform="translate(15 -196.26)"
                      />
                    </g>
                    <g
                      transform="matrix(1, 0, 0, 1, -15, -15)"
                      filter="url(#Path_168)"
                    >
                      <path
                        id="Path_168-2"
                        data-name="Path 168"
                        d="M236.762,437.333a2.1,2.1,0,0,0-2.095,2.095v3.192a2.095,2.095,0,1,0,4.189,0v-3.192a2.1,2.1,0,0,0-2.095-2.095Z"
                        transform="translate(-196.26 -378.71)"
                      />
                    </g>
                    <g
                      transform="matrix(1, 0, 0, 1, -15, -15)"
                      filter="url(#Path_169)"
                    >
                      <path
                        id="Path_169-2"
                        data-name="Path 169"
                        d="M442.619,234.667h-3.192a2.095,2.095,0,1,0,0,4.189h3.192a2.095,2.095,0,1,0,0-4.189Z"
                        transform="translate(-378.71 -196.26)"
                      />
                    </g>
                    <g
                      transform="matrix(1, 0, 0, 1, -15, -15)"
                      filter="url(#Path_170)"
                    >
                      <path
                        id="Path_170-2"
                        data-name="Path 170"
                        d="M385.677,75.28l-1.064,1.064a2.094,2.094,0,0,0,2.962,2.962l1.064-1.064a2.094,2.094,0,1,0-2.962-2.962Z"
                        transform="translate(-330.7 -52.22)"
                      />
                    </g>
                    <g
                      transform="matrix(1, 0, 0, 1, -15, -15)"
                      filter="url(#Path_171)"
                    >
                      <path
                        id="Path_171-2"
                        data-name="Path 171"
                        d="M76.344,79.306a2.094,2.094,0,1,0,2.962-2.962L78.242,75.28a2.094,2.094,0,0,0-2.962,2.962Z"
                        transform="translate(-52.22 -52.22)"
                      />
                    </g>
                    <g
                      transform="matrix(1, 0, 0, 1, -15, -15)"
                      filter="url(#Path_172)"
                    >
                      <path
                        id="Path_172-2"
                        data-name="Path 172"
                        d="M76.344,384.614l-1.064,1.064a2.094,2.094,0,0,0,2.962,2.962l1.064-1.064a2.094,2.094,0,0,0-2.962-2.962Z"
                        transform="translate(-52.22 -330.7)"
                      />
                    </g>
                    <g
                      transform="matrix(1, 0, 0, 1, -15, -15)"
                      filter="url(#Path_173)"
                    >
                      <path
                        id="Path_173-2"
                        data-name="Path 173"
                        d="M387.576,384.614a2.094,2.094,0,0,0-2.962,2.962c1,1,1.467,1.677,2.545,1.677a2.1,2.1,0,0,0,1.481-3.576Z"
                        transform="translate(-330.7 -330.7)"
                      />
                    </g>
                  </g>
                </g>
              </svg>
              <span>Sommer</span>
            </button>
          </li>
          <li>
            <button
              className={`mood-btn win ${mood === WINTER ? "active" : ""
                }`}
              onClick={setWinterHandler}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="67.26"
                height="72.573"
                viewBox="0 0 67.26 72.573"
              >
                <defs>
                  <filter
                    id="copo-de-nieve"
                    x="0"
                    y="0"
                    width="67.26"
                    height="72.573"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="5" result="blur" />
                    <feFlood floodOpacity="0.169" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                </defs>
                <g
                  transform="matrix(1, 0, 0, 1, 0, 0)"
                  filter="url(#copo-de-nieve)"
                >
                  <path
                    id="copo-de-nieve-2"
                    data-name="copo-de-nieve"
                    d="M50.248,29.945l-4.324-2.5,3.747-2.21a1.453,1.453,0,0,0-1.476-2.5L43.033,25.78,35.25,21.286l7.782-4.493L48.2,19.837a1.453,1.453,0,0,0,1.475-2.5l-3.748-2.209,4.325-2.5A1.453,1.453,0,0,0,48.8,10.111l-4.324,2.5-.039-4.351a1.453,1.453,0,0,0-2.905.026l.054,5.993L33.8,18.77V9.784l5.218-2.949a1.453,1.453,0,1,0-1.43-2.53L33.8,6.447V1.453a1.453,1.453,0,0,0-2.906,0V6.447L27.1,4.306a1.453,1.453,0,1,0-1.43,2.53l5.218,2.949V18.77l-7.783-4.493.054-5.993a1.453,1.453,0,0,0-1.44-1.466H21.71a1.453,1.453,0,0,0-1.452,1.44l-.039,4.351-4.324-2.5a1.453,1.453,0,1,0-1.453,2.516l4.325,2.5-3.748,2.209a1.453,1.453,0,1,0,1.475,2.5l5.164-3.043,7.782,4.493L21.656,25.78l-5.163-3.044a1.453,1.453,0,1,0-1.476,2.5l3.748,2.21-4.324,2.5a1.453,1.453,0,1,0,1.453,2.516l4.324-2.5.039,4.351a1.453,1.453,0,0,0,1.452,1.44h.013a1.453,1.453,0,0,0,1.44-1.466L23.109,28.3,30.892,23.8v8.986l-5.218,2.949a1.453,1.453,0,1,0,1.43,2.53l3.788-2.141V41.12a1.453,1.453,0,0,0,2.906,0V36.126l3.788,2.141a1.453,1.453,0,1,0,1.43-2.53L33.8,32.789V23.8L41.58,28.3l-.055,5.993a1.453,1.453,0,0,0,1.439,1.466h.014a1.453,1.453,0,0,0,1.452-1.439l.04-4.352,4.325,2.5a1.453,1.453,0,1,0,1.453-2.516Z"
                    transform="translate(1.29 15)"
                  />
                </g>
              </svg>
              <span>Winter</span>
            </button>
          </li>
        </ul>
        <HamburgerIcon open={open} toggleNavbarHandler={toggleNavbarHandler} />
      </div>
      <nav className={`menu-nav ${activeNavbarClasses}`}>
        <ul>
          {
            headerData !== null ?
              headerData.menu.map((item, i) => {
                return (
                  <li key={i}>
                    <NavLink
                      to={item.url}
                      className={({ isActive }) =>
                        isActive ? "active-link" : ''
                      }
                      onClick={closeNavbarHandlder}
                    >
                      {item.title}
                    </NavLink>
                  </li>
                );
              })
              :
              null
          }
        </ul>
      </nav>
    </header>
  );

};

export default Header;
