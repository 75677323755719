import axiosWordpress from "./axiosWordpress";
import { HOME_PAGE, HEADER, FOOTER, RESERVATION_FORM, CONTACT_FORM, UBER_UNS, FREIZEIT, GASTRONOMIE, NEWS, KONTAKT, IMPRESSUM, PRIVACY, GETSEASON, RESERVATION_FORM_INFO } from "./endpointsWordpress";

import { GET, POST } from "../methods";

const HomePageHttp = async (category) => {
  return axiosWordpress({
    method: GET,
    url: HOME_PAGE + `&category=${category}`,
  });
};

const HEADERHttp = async () => {
  return axiosWordpress({
    method: GET,
    url: HEADER,
  });
};

const FOOTERHttp = async () => {
  return axiosWordpress({
    method: GET,
    url: FOOTER,
  });
};

const UBER_UNSHttp = async (category) => {
  return axiosWordpress({
    method: GET,
    url: UBER_UNS + `&category=${category}`,
  });
};

const FREIZEITHttp = async (category) => {
  return axiosWordpress({
    method: GET,
    url: FREIZEIT + `&category=${category}`,
  });
};

const GASTRONOMIEHttp = async (category) => {
  return axiosWordpress({
    method: GET,
    url: GASTRONOMIE + `&category=${category}`,
  });
};

const NEWSHttp = async (category) => {
  return axiosWordpress({
    method: GET,
    url: NEWS + `&category=${category}`,
  });
};

const KONTAKTHttp = async (category) => {
  return axiosWordpress({
    method: GET,
    url: KONTAKT + `&category=${category}`,
  });
};

const IMPRESSUMHttp = async (category) => {
  return axiosWordpress({
    method: GET,
    url: IMPRESSUM + `&category=${category}`,
  });
};

const PRIVACYHttp = async (category) => {
  return axiosWordpress({
    method: GET,
    url: PRIVACY + `&category=${category}`,
  });
};

const sendReservationHttpRequest = async (data) => {
  return axiosWordpress({
    method: POST,
    url: RESERVATION_FORM,
    data: data
  });

};

const sendKontaktHttpRequest = async (data) => {
  return axiosWordpress({
    method: POST,
    url: CONTACT_FORM,
    data: data
  });

};

const getSeasonHTTP = async () => {
  return axiosWordpress({
    method: GET,
    url: GETSEASON,
  });
};

const reservationFormDetailsHttpRequest = async () => {
  return axiosWordpress({
    method: GET,
    url: RESERVATION_FORM_INFO,
  });
}

const subscriptionHttpRequest = async (data) => {
  throw Error('No endpoint provided only mock');
}

export { HomePageHttp, HEADERHttp, FOOTERHttp, UBER_UNSHttp, FREIZEITHttp, GASTRONOMIEHttp, NEWSHttp, KONTAKTHttp, IMPRESSUMHttp, PRIVACYHttp, sendReservationHttpRequest, sendKontaktHttpRequest, getSeasonHTTP, reservationFormDetailsHttpRequest, subscriptionHttpRequest };



