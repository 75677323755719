import { useState, createContext, useEffect } from "react";
import { SUMMER, WINTER } from "../../utils/moodTypes";
import { getSeasonHTTP } from '../../http/wordpress/http-requests-wordpress';
import { useLocation } from "react-router-dom";
import Spinner from '../../components/Spinner/Spinner';

const MoodContext = createContext({
    loading: true,
    mood: null,
    setSummerHandler: () => { },
    setWinterHandler: () => { },
    setLoading: () => { },
});

const MoodProvider = ({ children }) => {
    const location = useLocation();
    const [oldLocation, setOldLocation] = useState('');
    const [loading, setLoading] = useState(true);
    const [mood, setMood] = useState(null);

    const setSummerHandler = () => {
        setLoading(true);
        setMood(SUMMER);
        setTimeout(() => {
            setLoading(false);
        }, 500)
    };

    const setWinterHandler = () => {
        setLoading(true);
        setMood(WINTER);
        setTimeout(() => {
            setLoading(false);
        }, 500)
    };

    useEffect(() => {
        (async () => {
            try {
                const response = await getSeasonHTTP();
                if (response.status === 200) {
                    if (response.data.defaultSeason.toLowerCase() === 'summer') {
                        setMood(SUMMER)
                    } else {
                        setMood(WINTER);
                    }
                }
            } catch (error) {
                console.error(error);
            }
        })()
    }, []);


    useEffect(() => {
        if ((oldLocation !== location.pathname) && mood !== null) {
            setLoading(true);
            setOldLocation(location.pathname);
            window.scrollTo(0, 0);
        }
    }, [location, oldLocation, mood]);

    return (
        <MoodContext.Provider
            value={{
                mood: mood,
                loading: loading,
                setSummerHandler: setSummerHandler,
                setWinterHandler: setWinterHandler,
                setLoading: setLoading
            }}
        >
            {
                mood !== null ?
                    <div className={`main-wrapper ${mood === WINTER ? "winter" : "summer"}`}>
                        <Spinner state={loading ? 'active' : ''} />
                        <div className={`showApp ${loading ? '' : 'active'}`}>
                            {children}
                        </div>
                    </div> : null
            }
        </MoodContext.Provider >
    );

};

export { MoodContext, MoodProvider };