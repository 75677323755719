const POST = "POST";
const PUT = "PUT";
const DELETE = "DELETE";
const GET = "GET";

export {
    POST,
    PUT,
    DELETE,
    GET
}