import React from 'react'
import "./Spinner.scss";

const Spinner = ({ state }) => {
  return (
    <div className={`spinner-wrapper ${state}`}>
      <div className="spinner">
        <div className="cube1"></div>
        <div className="cube2"></div>
      </div>
    </div>
  );
}

export default Spinner