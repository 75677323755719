import React from 'react'
import './SocialIcons.scss';
const InstagramLogo = () =>{
    return(
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                <g id="InstagramLogo" transform="translate(-643 -2577)">
                    <circle id="Ellipse_44" className="fill-icon-background" data-name="Ellipse 44" cx="16" cy="16" r="16" transform="translate(643 2577)"/>
                    <path id="Path_123" className="fill-icon" data-name="Path 123" d="M-530.074,919.52c1.21,0,2.427-.078,3.63.02a3.71,3.71,0,0,1,3.58,3.63,47.309,47.309,0,0,1-.009,7.106,3.7,3.7,0,0,1-3.493,3.556,35.95,35.95,0,0,1-7.4-.013,3.644,3.644,0,0,1-3.4-3.466,37.368,37.368,0,0,1-.009-7.247,3.7,3.7,0,0,1,3.627-3.573C-532.4,919.446-531.234,919.518-530.074,919.52Z" transform="translate(1189.484 1665.966)" />
                    <path id="Path_124" className="fill-icon-background" data-name="Path 124" d="M-526.89,935.49c-1.008,0-2.021.061-3.023-.014a2.561,2.561,0,0,1-2.7-2.671c-.094-2.113-.1-4.236,0-6.348a2.47,2.47,0,0,1,2.55-2.654,60.658,60.658,0,0,1,6.5-.011c1.745.091,2.574,1.036,2.652,2.885.083,1.962.087,3.933,0,5.9s-.988,2.811-2.947,2.922c-1,.057-2.016.01-3.024.01Z" transform="translate(1186.233 1663.023)" />
                    <path id="Path_125" className="fill-icon" data-name="Path 125" d="M-518.083,934.527a3.725,3.725,0,0,1-3.7,3.791,3.74,3.74,0,0,1-3.8-3.7,3.8,3.8,0,0,1,3.711-3.816A3.772,3.772,0,0,1-518.083,934.527Z" transform="translate(1181.284 1658.078)" />
                    <path id="Path_126" className="fill-icon" data-name="Path 126" d="M-501.595,928.427a.876.876,0,0,1-.924.938.841.841,0,0,1-.861-.852.827.827,0,0,1,.923-.919A.819.819,0,0,1-501.595,928.427Z" transform="translate(1165.807 1660.318)" />
                    <g id="Group_121" data-name="Group 121" transform="translate(657.12 2590.307)">
                    <path id="Path_122" className="fill-icon-background" data-name="Path 122" d="M-518.557,940.209a2.315,2.315,0,0,1-2.334-2.438,2.3,2.3,0,0,1,2.34-2.268,2.326,2.326,0,0,1,2.351,2.271A2.361,2.361,0,0,1-518.557,940.209Z" transform="translate(520.892 -935.503)" />
                    </g>
                </g>
            </svg>

        </>
    );
}
export default InstagramLogo;