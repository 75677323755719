import React from 'react'
import "./HamburgerIcon.scss";

const HamburgerIcon = (props) => {
  const { open, toggleNavbarHandler } = props;

  const hamburgerIconClasses = open ? "active" : "";

  return (
    <div
      className={`nav-icon-holder ${hamburgerIconClasses}`}
      onClick={toggleNavbarHandler}
    >
      <div className="nav-icon">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

export default HamburgerIcon;
