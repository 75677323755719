const HOME_PAGE = "/pages?title=home";
const HEADER = "/header";
const FOOTER = "/footer";
const RESERVATION_FORM = "/reservationForm";
const CONTACT_FORM = "/contactForm";
const UBER_UNS = "/pages?title=uberuns";
const FREIZEIT = "/pages?title=freizeit";
const GASTRONOMIE = "/pages?title=gastronomie";
const NEWS = "/pages?title=news";
const KONTAKT = "/pages?title=kontakt";
const IMPRESSUM = "/pages?title=impressum";
const PRIVACY = "/pages?title=privacy";
const GETSEASON = "/season";
const RESERVATION_FORM_INFO = "/formlabels";

export { HOME_PAGE, HEADER, FOOTER, RESERVATION_FORM, CONTACT_FORM, UBER_UNS, FREIZEIT, GASTRONOMIE, NEWS, KONTAKT, IMPRESSUM, PRIVACY, GETSEASON, RESERVATION_FORM_INFO };

