import React from 'react'
import { Link } from "react-router-dom";

// Css
import './MenuIcon.scss';

const MenuIcon = (props) =>{
    const {menuLink} = props;
    return(
        <div className="menuIcon">
            <Link to={menuLink} target="_blank">
                <div className="menuIconInner">
                    <h2>Speisekarte</h2>
                    <svg xmlns="http://www.w3.org/2000/svg" width="28.105" height="26.485" viewBox="0 0 28.105 26.485">
                        <g id="Group_165" data-name="Group 165" transform="translate(-158.75 -92.25)">
                            <path id="Path_160" data-name="Path 160" d="M163.5,154.81H160.83a1.832,1.832,0,0,0-1.83,1.83v2.091a11.254,11.254,0,0,0,11.241,11.241h5.124a11.254,11.254,0,0,0,11.241-11.241V156.64a1.832,1.832,0,0,0-1.83-1.83h-2.668a3.8,3.8,0,0,0-3.757-3.764h-.006a3.731,3.731,0,0,0-2.03.594,4.807,4.807,0,0,0-7.159-.082,3.763,3.763,0,0,0-5.658,3.252ZM175.365,168.4h-5.124a9.685,9.685,0,0,1-9.64-8.888h24.4A9.685,9.685,0,0,1,175.365,168.4Zm9.672-11.764v1.307H160.569V156.64a.262.262,0,0,1,.261-.261h23.946A.262.262,0,0,1,185.037,156.64Zm-16.561-3.658a1.3,1.3,0,0,0,1.729-.241,3.239,3.239,0,0,1,5.04.065,1.3,1.3,0,0,0,1.8.233,2.176,2.176,0,0,1,1.3-.424h0a2.228,2.228,0,0,1,2.189,2.2h-15.47a2.242,2.242,0,0,1,2.112-2.194,2.169,2.169,0,0,1,1.3.366Z" transform="translate(0 -51.487)" fill="#464646" stroke="#464646" strokeWidth="0.5"/>
                            <path id="Path_161" data-name="Path 161" d="M285.069,96.421V93.284a.784.784,0,0,0-1.569,0v3.137a.784.784,0,1,0,1.569,0Z" transform="translate(-111.482)" fill="#464646" stroke="#464646" strokeWidth="0.5"/>
                            <path id="Path_162" data-name="Path 162" d="M315.069,109.957v-1.673a.784.784,0,1,0-1.569,0v1.673a.784.784,0,0,0,1.569,0Z" transform="translate(-138.345 -13.432)" fill="#464646" stroke="#464646" strokeWidth="0.5"/>
                            <path id="Path_163" data-name="Path 163" d="M255.069,108.957v-1.673a.784.784,0,0,0-1.569,0v1.673a.784.784,0,0,0,1.569,0Z" transform="translate(-84.618 -12.536)" fill="#464646" stroke="#464646" strokeWidth="0.5"/>
                        </g>
                    </svg>
                </div>
            </Link>
        </div>
    );
}

export default MenuIcon;