import { HelmetProvider } from "react-helmet-async";

import { MoodProvider } from "./shared/context/MoodContext";

// import ScrollTop from "./hoc/ScrollTop";

import Header from "./components/Header/Header";
import Router from "./routes/Routes";
import Footer from "./components/Footer/Footer";


import "./App.scss";
import "./Layout.scss";

const App = () => {

	return (

		<HelmetProvider>
			<MoodProvider >
				<Header />
				<Router />
				<Footer />
			</MoodProvider>
		</HelmetProvider >


	);
};

export default App;
