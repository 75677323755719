import React from 'react'
import './SocialIcons.scss';

const FacebookLogo = () =>{
    return(
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                <g id="facebookLogo" transform="translate(-596 -2577)">
                    <circle id="Ellipse_43" className="fill-icon-background" data-name="Ellipse 43" cx="16" cy="16" r="16" transform="translate(596 2577)" />
                    <g id="_x30_1._Facebook" transform="translate(608.078 2585.456)">
                    <path id="Icon_11_" className="fill-icon" d="M30.544,18.157H28.775c-.286,0-.375-.107-.375-.375V15.62c0-.286.107-.375.375-.375h1.769V13.673a3.968,3.968,0,0,1,.482-2.019,2.964,2.964,0,0,1,1.59-1.322,3.975,3.975,0,0,1,1.376-.232h1.751c.25,0,.357.107.357.357v2.037c0,.25-.107.357-.357.357-.482,0-.965,0-1.447.018a.645.645,0,0,0-.732.732c-.018.536,0,1.054,0,1.608h2.072c.286,0,.393.107.393.393v2.162c0,.286-.089.375-.393.375H33.563v5.824c0,.3-.089.411-.411.411H30.919c-.268,0-.375-.107-.375-.375Z" transform="translate(-28.4 -10.1)"/>
                    </g>
                </g>
            </svg>
        </>
    );
}


export default FacebookLogo;