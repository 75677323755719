import axios from "./axios";
import { GET, POST } from './methods';
import { INSTAGRAM_POSTS, INSTAGRAM_POST_IMAGES, SUBSCRIBE_EMAIL } from "./endpoints";

const getInstagramPostsHttp = async () => {
    return axios({
        method: GET,
        url: INSTAGRAM_POSTS,
    });
};

const getInstagramPostImagesHttp = async (id) => {
    return axios({
        method: GET,
        url: INSTAGRAM_POST_IMAGES + '/' + id,
    });
};

const subscribeEmailHttp = async (data) => {
    return axios({
        method: POST,
        url: SUBSCRIBE_EMAIL,
        data: data
    });
};

export { getInstagramPostsHttp, getInstagramPostImagesHttp, subscribeEmailHttp };